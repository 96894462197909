.modal-perguntas .modal-content{
    background-color: var(--secondary-color);
    border-radius: 10px;
    border: none;
    height: 50vh;
    color: var(--tertiary-color);
}
.modal-perguntas .modal-header{
    border-color: var(--tertiary-color);
}
.modal-perguntas .modal-footer{
    border-color: var(--tertiary-color);
}
.modal-perguntas .modal-header p{
    margin-bottom: 0;
    font-size: 19px;
}
.modal-perguntas .modal-body{
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--tertiary-color);
}
.modal-perguntas .modal-body p{
    font-size: 18px;
    width: 80%;
    text-align: center;
    margin-bottom: 4%;
}
.modal-perguntas .star{
    font-size: 38px !important;
    color: var(--dark-orange-color);
    cursor: pointer;
    margin: 0 0.3vw;
}
.filled{
    color: var(--dark-orange-color) !important;
}