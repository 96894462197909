.active {
    color: var(--primary-color);
}

.header-aluno{
    background-color: var(--header-secundary-color);
    padding: 1% 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 5;
    width: 100%;
    height: 10vh;
    color: var(--primary-text-color);
}
.header-aluno img{
    width: 140px;
}
.header-aluno section{
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 1vw;
    font-size: 17px;
    font-weight: 500;
    margin-left: -2vw;
}
.header-aluno section a{
    transition: 0.2s ease-in;
    font-weight: 600;
}
.header-aluno section a svg{
    margin-left: 5px;
    font-size: 14px;
}
.header-aluno .btn-adm button{
    background-color: var(--primary-color);
    border: none;
    font-size: 16px;
}
.header-aluno .btn-adm ul li a{
    color: var(--primary-text-color);
    font-size: 16px;
}
.header-aluno section a:hover{
    color: var(--primary-color);
}
.header-aluno .btn-azul{
    background-color: var(--primary-color);
    border: none;
    padding: 0.6vh 1.5vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: 0.8rem;
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.3s ease-in-out;
}
.header-aluno .btn-azul:hover{
    background-color: var(--primary-color-2);
}
.header-aluno .dropdown-menu{
    background-color: var(--header-secundary-color);
}
.header-aluno .dropdown-item{
    width: 100%;
    justify-content: start;
}
.header-aluno .dropdown-item:hover{
    color: var(--primary-color);
}
.ultima-section{
    display: flex;
    align-items: center;
    gap: 1.2vw;
}
.ultima-section a:first-child{
    font-weight: 600;
}
.ultima-section img{
    width: 47px;
    min-width: 47px;
    max-width: 47px;
    height: 47px;
    min-height: 47px;
    max-height: 47px;
    border-radius: 50%;
    border: 2px solid var(--tertiary-color);
}
.ultima-section button{
    font-weight: 700 !important;
}




#header-mobile{
    font-size: 27px;
    margin-right: 3vw;
    cursor: pointer;
    color: var(--tertiary-color);
}
.dropdown-overlay div{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    padding-right: 6.5vw;
    background-color: var(--header-secundary-color);
}
.dropdown-overlay div svg{
    font-size: 34px;
    height: 10vh;
}
.dropdown-overlay section{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 90vh;
    gap: 2vh;
    background-color: var(--header-secundary-color);
    margin-left: 0;
}
.header-aluno .dropdown-overlay section{
    padding: 0 9vw 0 0 !important;
}
.header-aluno .dropdown-overlay section img{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    border-radius: 30%;
    border: 2px solid var(--tertiary-color);
}
.header-aluno .dropdown-overlay section p{
    font-size: 13px;
    color: var(--primary-color);
    margin-bottom: 0;
}
.header-aluno .dropdown-overlay section button{
    padding: 0.7vh 5vw;
}
.dropdown-overlay section button{
    background-color: var(--primary-color);
    border: none;
    padding: 0.7vh 4vw;
    font-weight: 600;
    border-radius: 5px;
    font-size: var(--h6-font-size);
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.4s ease-in-out;
}
.dropdown-overlay section svg{
    font-size: 18px !important;
}
.dropdown-overlay {
    position: fixed !important;
    top: 0;
    right: -70%; 
    width: 70%;
    height: 100vh;
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    transition: right 0.3s ease-in-out;
    z-index: 9999;
}
.dropdown-overlay.open {
    right: 0;
}
.slide-in {
    transform: translateX(0);
}






/* header web */
@media screen and (min-width: 1081px){
    #header-web{
        display: flex;
    }
    #header-mobile{
        display: none;
    }
}
/* header mobile */
@media screen and (max-width: 1080px){
    #header-web{
        display: none;
    }
    #header-mobile{
        display: flex;
    }
    .header-aluno img{
        width: 120px;
    }
    .btn-adm {
        height: 6vh !important;
        padding-right: 0 !important;
    }
}