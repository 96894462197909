.sumir-renda{
    font-size: 13px;
    margin-bottom: 1.5vh !important;
}

.body-dashboard{
    background-color: var(--secondary-color);
    height: 100%;
    color: var(--primary-text-color);
    padding: 5% 0 1% 0;
}

.body-dashboard .capa{
    width: 100%;
    height: 230px;
}
.conteudo-dashboard{
    display: flex;
    width: 100%;
    padding: 0 5%;
    display: flex;
    justify-content: space-between;
}
.blocos-dashboard{
    background-color: var(--header-secundary-color);
    border-radius: 10px;
    box-shadow: 1px 1px 7px var(--tertiary-color);
}

.left-dashboard{
    position: relative;
    top: -7vh;
    width: 22%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}




.dashboard-perfil{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.dashboard-perfil img, .imgperfil{
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    border-radius: 50%;
    border: 3px solid var(--tertiary-color);
    margin: 30px 0 20px 0;
    object-fit: cover;
}
.dashboard-perfil h1{
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 5px 0;
    width: 90%;
    text-align: center;
}
.dashboard-perfil h6{
    font-size: 14px;
    margin: 0 0 20px 0;
    text-align: center;
    width: 80%;
}
.dashboard-perfil span{
    font-size: 12px;
    font-weight: 300;
    display: flex;
    align-items: center;
}
.dashboard-perfil span svg{
    font-size: 15px;
    margin-right: 3px;
    margin-top: -5px;
}
.dashboard-perfil button{
    margin: 15px 0 0 0;
    border-radius: 7px;
    padding: 0.8vh 5%;
}
.dashboard-perfil p{
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 20px;
}
.divisao-dashboard{
    margin: 20px 0 0 0;
    border-bottom: 2px solid var(--tertiary-color);
    width: 100%;
}


.btn-certificado{
    width: 100%;
}
.btn-certificado svg{
    font-size: 24px;
    margin-right: 1.5%;
}
.btn-certificado button{
    width: 100%;
    background-color: var(--primary-color-3);
    transition: 0.4s ease-in-out;
}
.btn-certificado button:hover{
    background-color: var(--primary-color);
}


.bloco-plano{
    margin-bottom: 2.5vh;
    padding: 4% 0 3% 0;
}
.bloco-plano h2{
    font-size: 22px;
    margin-left: 0%;
    margin-bottom: 3%;
    padding-left: 8%;
    padding-bottom: 3%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.bloco-plano div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4% 0;
}
.bloco-plano div p{
    margin-bottom: 0;
    padding: 0% 0 0% 8%;
}
.bloco-plano div h1{
    padding-right: 8%;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}
.bloco-plano button{
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    padding: 0.6vh 0.2vw;
    font-weight: 700;
    border-radius: 5px;
    font-size: 13px;
    width: 40%;
    margin: 4% 0 6% 29%;
    transition: 0.3s ease-in-out;
}


.dashboard-bloco-pequeno{
    width: 100%;
}
.dashboard-bloco-pequeno p{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5% 0 5% 8%;
}
.dashboard-bloco-pequeno p h1{
    padding-right: 8%;
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 600;
}




.dashboard-rede{
    width: 25%;
    padding: 20px 0 0 0;
    margin: 20px 0 0 0;
    height: 75vh;
}
.rede-overflow{
    overflow-y: scroll;
    overflow-x: hidden;
    height: 65vh;
}
.conteudo-dashboard  .dashboard-rede .rede-overflow::-webkit-scrollbar{
    width: 6px;
    background-color: var(--secondary-color);
    border: none;
}
.dashboard-rede h1{
    margin-left: 5%;
    margin-bottom: 6%;
    font-size: 22px;
}
.rede{
    display: flex;
    align-items: center;
    gap: 10px;
    border-top: 1px solid;
    border-color: var(--tertiary-color);
    padding: 4% 0;
}
.rede h6{
    margin-left: 10px;
    width: 8%;
    font-size: 16px;
    font-weight: 600;
    padding-top: 5px;
}
.rede img{
    object-fit: cover;
    width: 44px;
    min-width: 44px;
    max-width: 44px;
    height: 44px;
    min-height: 44px;
    max-height: 44px;
    border-radius: 50%;
    border: 2px solid var(--tertiary-color);
}
.rede div{
    width: 70%;
}
.rede p{
    font-size: 14px;
    font-weight: 600;
    width: 95%;
    margin-bottom: 0;
}
.rede span{
    font-size: 10px;
    width: 100%;
    font-weight: 600;
    flex-wrap: wrap;
    color: var(--primary-color-3);
}
.rede-line{
    line-height: 2vh;
}



.dashboard-sobre-mim{
    margin: 20px 0;
    width: 43%;
    height: 65vh;
}
.dashboard-sobre-mim svg{
    position: absolute;
    right: 6%;
    margin-top: 1%;
    font-size: 22px;
    cursor: pointer;
}
.dashboard-sobre-mim h1{
    font-size: 22px;
    margin-left: 3%;
    padding-top: 20px;
}

.dashboard-sobre-mim p{
    margin-left: 4%;
    padding-right: 10%;
    height: 100%;
    padding-bottom: 40px;
}
.bloco-sobre-mim{
    height: 83%;
}
.bloco-sobre-mim h1{
    margin-left: 0%;
    margin-bottom: 3%;
    padding-left: 3%;
    padding-bottom: 3%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.bloquinhos{
    display: flex;
    margin-left: 0;
    justify-content: space-between;
    margin-top: 3%;
}
.bloquinhos p{
    background-color: var(--secondary-color);
    border-radius: 10px;
    display: flex;
    width: 48%;
    margin-left: 0;
    align-items: center;
    justify-content: space-between;
    padding: 2% 0 2% 3.5%;
    box-shadow: 1px 1px 7px var(--tertiary-color);
}
.bloquinhos p h1{
    padding-right: 8%;
    font-size: 27px;
    padding-top: 0;
    margin-bottom: 0;
}



.modal-dashboard .presencial-botao{
    width: 70%;
    margin-top: 2px;
}
.btn-close{
    --bs-btn-close-focus-shadow: transparent;
}
.modal-dashboard .modal-content{
    border-radius: 30px;
}
.modal-dashboard .modal-header{
    background-color: var(--secondary-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 40vw;
    color: var(--primary-text-color);
}
.modal-dashboard .modal-header button:first-child{
    background-color: var(--primary-color-3);
    color: var(--primary-text-color);
}
.modal-dashboard .modal-header button:first-child:hover{
    background-color: var(--primary-color);
}
.modal-dashboard .modal-body{
    background-color: var(--secondary-color);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: 75vh;
    width: 40vw;
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color);
    padding: 0 0 10px 0;
}
.modal-dashboard .modal-content{
    border: none;
    height: 75vh;
    width: 40vw;
}
.modal-body-capa{
    width: 100%;
}
.conteudo-modal-body{
    display: flex;
    flex-direction: column;
    padding-left: 4%;
    padding-right: 4%;
}
.conteudo-modal-body img{
    width: 90px;
    min-width: 90px;
    max-width: 90px;
    height: 90px;
    min-height: 90px;
    max-height: 90px;
    margin-top: -40px;
    border-radius: 50%;
    border: 3px solid var(--tertiary-color);
    margin-bottom: 7px;
}
.label-avatar{
    cursor: pointer;
    display: flex;
    flex-direction: column;
}
.label-avatar svg{
    z-index: 99;
    position: absolute;
    margin-left: 5.7%;
    margin-top: -2%;
    opacity: 0.9;
    transition: all 0.5s;
}
.label-avatar svg:hover{
    opacity: 1;
    transform: scale(1.3);
}
.botao-upload{
    background-color: var(--green-color) !important;
    width: 23%;
    border: none;
    padding: 0.7% 1%;
    border-radius: 5px;
    font-size: 12px;
    box-shadow: 1px 1px 5px var(--tertiary-color);
    transition: 0.4s ease-in-out;
    margin-bottom: 5%;
}
.botao-upload:hover{
    background-color: var(--2-green-color);
}
.input-modal{
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid var(--tertiary-color);
    margin-bottom: 4%;
    border-radius: 4px;
    padding: 5px 9px;
}
.input-modal label{
    font-size: 12px;
    color: var(--primary-color-4);
    margin-bottom: 2px;
}
.input-modal input, textarea{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--primary-text-color);
}
.select-dashboard{
    margin-bottom: 0;
    background-color: transparent;
    border: none;
    width: 30%;
    outline: none;
    color: var(--primary-text-color);
}
.select-dashboard option{
    color: var(--primary-text-color);
    background-color: var(--secondary-color);
}



.modal-dashboard2 .modal-body{
    height: 50vh;
}
.modal-body2{
    padding: 4% !important;
}
.modal-body2 div textarea{
    height: 39vh;
    max-height: 39vh;
}
.botao-modal-dashboard2{
    width: 25%;
}



.modal-cancelar-plano .modal-header {
    border-bottom: none;
    width: 30vw;
}
.modal-cancelar-plano .modal-header button{
    background-color: transparent !important;
}
.modal-cancelar-plano .modal-body{
    width: 30vw;
}
.modal-cancelar-plano .modal-content{
    height: 70vh;
    width: 30vw;
    background-color: transparent;
    margin-left: 7%;
}

.modal-body-cancelar1, .modal-body-cancelar2{
    padding: 6% 5% 5% 5% !important;
    display: flex;
    align-items: center;
    text-align: center;
}
.modal-body-cancelar1 svg{
    font-size: 75px;
    margin-bottom: 6%;
}
.modal-body-cancelar1 h6{
    font-size: 21px;
    font-weight: 600;
    width: 80%;
    margin-bottom: 4%;
}
.modal-body-cancelar1 p{
    width: 80%;
    font-weight: 400;
    margin-bottom: 7%;
}
.modal-body-cancelar1 .btn-prosseguir{
    background-color: var(--primary-color-3);
    border: none;
    padding: 0.6vh 3.7vw;
    border-radius: 5px;
    font-size: 16px;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.3s ease-in-out;
    margin-bottom: 2%;
}
.modal-body-cancelar2 .btn-cancelar{
    background-color: var(--dark-red-color);
    border: none;
    padding: 0.6vh 1.5vw;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    box-shadow: 1px 1px 8px var(--tertiary-color);
    transition: 0.3s ease-in-out;
    margin-bottom: 2%;
}
.modal-body-cancelar2 .btn-cancelar:hover{
    background-color: var(--2-dark-red-color);
}
.modal-cancelar-plano .btn-voltar{
    background-color: transparent;
    border: 1px solid var(--tertiary-color);
    padding: 0.6vh 4.9vw;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.3s ease-in-out;
}
.modal-body-cancelar1 .btn-prosseguir:hover{
    background-color: var(--primary-color);
}
.modal-body-cancelar2{
    padding: 2% 5% 5% 5% !important;
}
.modal-body-cancelar2 p{
    font-weight: 600;
    width: 80%;
}
.modal-body-cancelar2 div{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
}
.modal-body-cancelar2 div label input{
    margin-right: 0.7vw;
}



/* celular e tablet */
@media screen and (max-width: 1080px){
    .body-dashboard{
        padding: 10vh 0 5% 0;
    }
    .body-dashboard .capa{
        width: 100%;
        height: 100%;
    }
    .conteudo-dashboard{
        flex-direction: column;
        padding: 0 10%;
        align-items: center;
    }
    .left-dashboard{
        top: 3vh;
        width: 90%;
    }
    .dashboard-perfil button{
        margin: 15px 0 0 2vw;
        text-align: center;
    }
    .dashboard-rede{
        width: 90%;
        margin: 3.5vh 0 0 0;
    }
    .dashboard-rede h1{
        font-size: 18px;
    }
    .dashboard-sobre-mim{
        margin: 25px 0;
        width: 90%;
        height: 50vh;
    }
    .dashboard-sobre-mim svg{
        right: 17%;
        margin-top: 3%;
    }
    .dashboard-sobre-mim h1{
        font-size: 18px;
    }

    
    .modal-dashboard .modal-header{
        width: 80vw;
        margin-left: 5vw;
    }
    .modal-dashboard .modal-header h5{
        width: 50%;
    }
    .modal-dashboard .modal-body{
        height: 65vh;
        width: 80vw;
        margin-left: 5vw;
    }
    .modal-dashboard .modal-content {
        height: 65vh;
        width: 80vw;
        margin-left: 3vw;
        background-color: transparent !important;
    }
    .modal-dashboard .presencial-botao {
        width: 50%;
    }
    .botao-modal-dashboard2{
        width: 80px;
        margin-right: 1vw;
    }
    .label-avatar svg {
        margin-left: 10%;
        margin-top: -2.5%;
    }
    .botao-upload {
        width: 34%;
    }
    .modal-dashboard .modal{
        --bs-modal-width: 100vw;
    }
    .modal-cancelar-plano .modal-header {
        width: 80vw;
    }
    .modal-cancelar-plano .modal-body{
        width: 80vw;
    }
    .modal-cancelar-plano .modal-content{
        height: 55vh;
        width: 80vw;
        margin-left: 2.5%;
    }






    .modal-body-cancelar1, .modal-body-cancelar2{
        padding: 6% 5% 5% 5% !important;
    }
    .modal-body-cancelar1 svg{
        font-size: 70px;
    }
    .modal-body-cancelar1 h6{
        width: 90%;
    }
    .modal-body-cancelar1 p{
        width: 95%;
    }
    .modal-body-cancelar1 .btn-prosseguir{
        padding: 0.6vh 7vw;
        margin-bottom: 3%;
    }
    .modal-body-cancelar2 .btn-cancelar{
        padding: 0.6vh 4vw;
        margin-bottom: 3%;
    }
    .modal-body-cancelar2 .btn-voltar{
        padding: 0.6vh 16vw;
    }
    .modal-body-cancelar1 .btn-voltar{
        padding: 0.6vh 11vw;
    }
    .modal-body-cancelar2{
        padding: 4% 5% 5% 5% !important;
    }
    .modal-body-cancelar2 p{
        width: 95%;
    }
    .modal-body-cancelar2 div label input{
        margin-right: 1.5vw;
    }




    .bloquinhos{
        flex-direction: column;
        margin-top: 6%;
    }
    .bloquinhos p{
        width: 100%;
        padding: 5% 0 5% 4.5%;
    }
    .bloquinhos p h1{
        padding-right: 8%;
        font-size: 27px;
        padding-top: 0;
        margin-bottom: 0;
    }
}