.body-curso-online{
    background-color: var(--secondary-color);
    height: 100%;
    display: flex;
    flex-direction: column;
    color: var(--primary-text-color);
}


.header-online{
    height: 10vh;
    display: flex;
    align-items: center;
    background-color: var(--header-secundary-color);
    padding: 0 4%;
    width: 100%;
    position: fixed;
    z-index: 2;
}
.header-online img{
    width: 100px;
}
.header-online #header-web{
    width: 60%;
    gap: 2%;
    margin: 0 0 0 3vw;
}


.body-curso-online .textos{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 17vh 0 4% 0;
}
.body-curso-online .textos h1{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 2%;
    width: 70%;
}
.body-curso-online .textos h1 span{
    color: var(--primary-color-3);
}
.body-curso-online .textos p{
    width: 57%;
    color: var(--primary-text-color);
    font-size: 18px;
}
.body-curso-online .adquirir-curso{
    margin-top: 1.5%;
    background-color: var(--primary-color-3);
    color: var(--secondary-text-color);
    border: none;
    padding: 0.8% 4%;
    border-radius: 7px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    box-shadow: 1px 1px 6px var(--tertiary-color);
    transition: 0.5s ease-in-out;
    outline: none;
}
.body-curso-online .adquirir-curso:hover{
    background-color: var(--primary-color);
}
.body-curso-online .adquirir-curso button{
    background-color: transparent;
    border: none;
}


.video-curso{
    padding: 0 0 5% 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.video-curso h3{
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 2%;
}
.body-curso-online iframe{
    border-radius: 8px;
    width: 860px;
    height: 484px;
}


.oque-aprender{
    padding: 5% 7% 6% 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    background-color: var(--primary-color-3);
    display: flex;
    align-items: center;
    justify-content: center;
}
.oque-aprender ul{
    list-style: none;
    border: 1px solid var(--tertiary-color);
    border-radius: 6px;
    width: 80%;
    padding: 3% 5%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}
.oque-aprender ul h3{
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 4%;
    color: var(--primary-text-color);
    text-align: center;
}
.oque-aprender ul li{
    width: 48%;
    margin-bottom: 2%;
    color: var(--primary-text-color);
}
.oque-aprender ul li svg{
    font-size: 27px;
    margin-right: 1.5%;
    color: var(--tertiary-color);
}


.porque-var{
    padding: 5% 7% 2% 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    background-color: var(--secondary-color);
}
.porque-var h3{
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}
.blocos-web{
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column !important;
    gap: 4% !important;
    width: 100%;
    margin: 3% 0;
}
.blocos-web div{
    cursor: default;
    border-radius: 9px;
    color: var(--primary-text-color);
}
.blocos-web div h6 svg{
    font-size: 27px;
    width: 30%;
    color: var(--tertiary-color);
}
.blocos-web div h6{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    padding: 4% 5% 0 0;
    height: 65px;
    margin-right: 15px;
    margin-bottom: 0;
    background-color: var(--primary-color-4);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.blocos-web div p{
    font-size: 14px;
    padding: 3% 3% 0 4%;
    background-color: var(--primary-color-4);
    height: 120px;
    margin-right: 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}






.body-curso-online .curso{
    padding: 5% 7%;
    border-bottom: 1px solid;
    text-align: center;
    border-color: var(--tertiary-color);
    background-color: var(--primary-color-4);
    color: var(--secondary-text-color);
}
.body-curso-online .curso h1{
    font-size: 28px;
    font-weight: 600;
}
.body-curso-online .curso p{
    padding: 0 15%;
}
.body-curso-online .curso .p-2{
    margin-bottom: 3%;
}
.body-curso-online .curso h3{
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 4%;
}


.detalhes-curso{
    padding: 5% 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
}
.detalhes-curso h3{
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}  
.detalhes-p{
    margin-bottom: 2%;
    text-align: center;
    padding: 0 15%;
}
.curso-online-container3{
    padding: 3% 0 5% 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.curso-online-container3 h3{
    font-weight: 600;
    margin-bottom: 3%;
    font-size: 24px;
}
.curso-online-container3 p, .curso-online-container3 .accordion-online{
    margin-left: 27%;
}


.accordion-online{
    width: 45%;
    max-height: 55vh;
    border: 1px solid var(--tertiary-color);
    margin-bottom: 2%;
}
.accordion-online .botao2{
    background-color: var(--primary-color-4) !important;
}
.accordion-online-modulos{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 !important;
}
.accordion-online-modulos .botao1{
    background-color: var(--secondary-color) !important;
}
.accordion-online-modulos .div1{
    width: 100%;
}
.accordion-online-modulos ul{
    list-style: none;
    width: 100%;
    padding: 0;
    background-color: var(--secondary-color);
}
.accordion-online-modulos li{
    display: flex;
    align-items: center;
    padding: 3% 3%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    justify-content: space-between;
    color: var(--primary-text-color);
}
.accordion-online-modulos li div{
    display: flex;
    align-items: center;
    justify-content: start;
    width: 90%;
}
.accordion-online-modulos li div svg{
    width: 20px;
    min-width: 20px;
    font-size: 18px;
    margin-right: 1vw;
    margin-top: -1px;
    color: var(--primary-color-3);
}
.accordion-online-modulos li span{
    color: var(--primary-text-color);
    font-size: 0.8rem;
    font-weight: 500;
}
.accordion-online-aulas{
    padding: 0 !important;
}


.quanto-ganha{
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20%;
}
.quanto-ganha p{
    margin-bottom: 0;
}
.quanto-ganha h6{
    margin-bottom: 0;
}
.quanto-ganha span{
    border-top: 3px solid var(--primary-color);
    font-size: 8px;
    padding: 0 7.5vw;
    color: transparent;
}
.quanto-ganha div:nth-child(2){
    text-align: center;
}
.quanto-ganha div:nth-child(3){
    text-align: end;
}


.professores{
    padding: 5% 7% 0 7%;
    border-bottom: 1px solid;
    border-color: var(--tertiary-color);
    background-color: var(--tertiary-color);
    color: var(--secondary-text-color);
}
.professores h3{
    text-align: center;
    font-size: 29px;
    font-weight: 600;
}
.professores .perfis{
    padding: 4% 0 5% 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.professores .perfis .perfil{
    width: 29%;
}
.professores .perfil section{
    display: flex;
    align-items: center;
}
.professores .perfil img{
    width: 90px;
    border-radius: 50%;
    margin-bottom: 5%;
}
.professores .perfil section div{
    padding-left: 5%;
    margin-top: -20px;
    width: 100%;
}
.professores .perfil section div p{
    margin-bottom: 0;
    font-weight: 600;
    font-size: 19px;
    text-align: justify;
}
.professores .perfil p{
    text-align: justify;
}
.professores .perfil section div span{
    font-size: 15px;
    color: var(--primary-color);
    font-weight: 500;
}




/* celular e tablet */
@media screen and (max-width: 1080px) {
    .header-online{
        padding: 0 6%;
        justify-content: space-between;
    }
    .header-online img{
        width: 70px;
    }
    .header-online svg{
        font-size: 27px;
        cursor: pointer;
    }
    .body-curso-online .textos{
        padding: 15vh 5% 9% 5%;
    }
    .body-curso-online .textos h1{
        font-size: 23px;
        margin-bottom: 4%;
        width: 100%;
    }
    .body-curso-online .textos p{
        width: 100%;
    }
    .body-curso-online .adquirir-curso{
        margin-top: 3%;
        padding: 3% 15%;
        font-size: 14px;
    }
    .video-curso{
        padding: 5% 0 6% 0;
    }
    .video-curso h3{
        font-size: 20px;
        margin-bottom: 4%;
    }
    .body-curso-online iframe{
        border-radius: 8px;
        width: 350px;
        height: 198px;
    }
    .oque-aprender{
        padding: 10% 7%;
    }
    .oque-aprender ul{
        border-radius: 5px;
        width: 100%;
        padding: 5%;
    }
    .oque-aprender ul h3{
        font-size: 20px;
        margin-bottom: 5%;
    }
    .oque-aprender ul li{
        width: 100%;
        margin-bottom: 4%;
    }
    .oque-aprender ul li svg{
        font-size: 25px;
        margin-right: 2%;
    }
    .slick-prev:before {
        display: none;
    }
    .slick-next:before {
        display: none;
    }
    .porque-var{
        padding: 10% 7%;
    }
    .porque-var h3{
        margin-bottom: 6%;
        font-size: 20px;
    }
    .carousel-container {
        width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
    .slick-center .carousel-item {
        transform: scale(1.1);
        transition: background-color 0.3s, transform 0.3s;
        width: 100vw !important;
        height: 22vh;
        /* background-color: #1e1e21;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.511); */
    }
    /* .slick-dots {
        margin-bottom: 3% !important;
    } */
    .porque-var .blocos{
        width: 100%;
    }
    .porque-var .blocos div{
        width: 100%;
        display: flex;
        padding: 1% 2% 0% 2.5%;
        border-radius: 9px;
        max-height: 40vh;
        margin: 0 6px 1% 6px;
    }
    .porque-var .blocos div h6{
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 7% 0 0 0;
        cursor: grab;
        width: 50vw;
    }
    .porque-var svg{
        font-size: 26px;
        width: 25%;
        margin-right: 2.5vw;
        color: var(--tertiary-color);
        padding: 0 0 0 4%;
        cursor: grab;
    }
    .porque-var .blocos div p{
        font-size: 14px;
        width: 50vw;
        padding: 2% 3% 0 4%;
        cursor: grab;
    }





    .body-curso-online .curso{
        padding: 10% 7%;
    }
    .body-curso-online .curso h1{
        font-size: 23px;
    }
    .body-curso-online .curso p{
        padding: 0 0;
    }
    .body-curso-online .curso .p-2{
        margin-bottom: 8%;
    }
    .body-curso-online .curso h3{
        font-size: 16px;
        margin-bottom: 8%;
    }
    .detalhes-curso{
        padding: 10% 7% 7% 7%;
    }
    .detalhes-curso h3{
        font-size: 20px;
        text-align: start;
    }
    .detalhes-p{
        width: 100%;
        margin-bottom: 6%;
        text-align: justify;
        padding: 0 0;
    }
    .curso-online-container3{
        padding: 5% 0;
    }
    .curso-online-container3 h3{
        margin-bottom: 2%;
        font-size: 20px;
    }
    .curso-online-container3 p, .curso-online-container3 .accordion-online{
        margin-left: 0;
    }
    .accordion-online{
        width: 100%;
        margin-bottom: 7%;
        max-height: 45vh;
    }
    .accordion-online-modulos li div svg{
        margin-right: 2vw;
    }
    .quanto-ganha{
        margin-top: 7%;
        padding: 0 0;
    }
    .quanto-ganha div{
        margin-bottom: 2%;
    }
    .quanto-ganha span{
        border-top: 2px solid var(--primary-color);
        padding: 0 18vw;
    }
    .professores{
        padding: 10% 7% 2% 7%;
    }
    .professores h3{
        font-size: 23px;
    }
    .professores .perfis{
        padding: 6% 0;
        flex-direction: column;
    }
    .professores .perfil{
        margin-bottom: 8%;
        width: 100% !important;
    }
    .professores .perfil img{
        width: 80px;
    }
}